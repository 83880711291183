<template>
  <div class="Userhl_home">
    <div class="Userhl_home_t">
      <div class="Userhl_home_tl">
        <div class="Userhl_home_tll">
          <p>
            <icon-svg icon-class="iconpurse" />
            总资产
          </p>
          <p>￥ {{ can_moneyll }}</p>
          <p class="tixianios">可提现金额：{{ userdata.can_money }}</p>
          <p>冻结金额：{{ userdata.freeze_money }}</p>
        </div>
        <div class="Userhl_home_tlr">
          <div @click="tixianbtn">提现</div>
          <div @click="chongzhitype = true">充值</div>
        </div>
      </div>
      <div class="Userhl_home_tx"></div>
      <div class="Userhl_home_tl">
        <div class="Userhl_home_tll">
          <p>
            <icon-svg icon-class="iconpurse" />
            收支
          </p>
          <p>
            <span>￥{{ userdata.shouru }}</span>
            <span>￥{{ userdata.zhichu }}</span>
          </p>
          <p>
            <span>总收入</span>
            <span>总支出</span>
          </p>
        </div>
        <div class="Userhl_home_tlr">
          <div>
            <router-link to="/Userhome/Userhl_fapiaolie">申请发票</router-link>
          </div>
          <div>
            <router-link to="/Userhome/Userhl_fapiao">发票管理</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="Userhl_home_b">
      <el-date-picker
        value-format="yyyy-MM-dd"
        v-model="timeall"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        class="zhangdantime"
      >
      </el-date-picker>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="收入明细" name="first">
          <div class="Userhl_home_b_sbox">
            <el-table :data="tableData" border style="width: 100%" stripe>
              <el-table-column
                prop="class_id"
                label="收入类型"
                style="width: 33%"
              >
              </el-table-column>
              <el-table-column prop="money" label="收入金额" style="width: 33%">
              </el-table-column>
              <el-table-column
                prop="create_time"
                label="收入时间"
              ></el-table-column>
            </el-table>
            <el-pagination
              :pager-count="5"
              @current-change="currentchange"
              :page-size="pagesize"
              background
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="支出明细" name="two">
          <div class="Userhl_home_b_zbox">
            <el-table :data="tableData" border style="width: 100%" stripe>
              <el-table-column
                prop="class_id"
                label="支出类型"
                style="width: 33%"
              >
              </el-table-column>
              <el-table-column prop="money" label="支出金额" style="width: 33%">
              </el-table-column>
              <el-table-column
                prop="create_time"
                label="支出时间"
              ></el-table-column>
            </el-table>
            <el-pagination
              :pager-count="5"
              @current-change="currentchange"
              :page-size="pagesize"
              background
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="请选择提现类型" :visible.sync="tixiantype" width="30%">
      <div>
        <p class="tixianzhanghut">
          提现账户：<span class="tixiantitle">{{ r_zfbnum }}</span>
        </p>
        <p class="tixianbtmtext">
          ( 提现账户为用户实名认证时填写的的支付宝账户！)
        </p>
        <div class="zhifubox_type">
          <el-radio v-model="radioval" label="2" style="margin-right: 44px"
            >余额提现
          </el-radio>
          <span class="zhifubox_typem">￥{{ userdata.can_money }}</span>
        </div>
        <div class="zhifubox_type" v-if="tixianyuetype" style="flex-wrap: wrap">
          <el-input
            v-model="tixianyuenum"
            style="width: 60%"
            :placeholder="'请输入提现金额（最多￥' + userdata.can_money + '）'"
          ></el-input>
          <br />
          <el-input
            v-model="zfpaypass"
            style="width: 60%; margin-top: 2rem"
            show-password
            placeholder="请输入您的支付密码"
          ></el-input>
        </div>
        <div class="zhifubox_type">
          <el-radio v-model="radioval" label="1">保证金提现</el-radio>
          <span class="zhifubox_typem">￥{{ userdata.bond_money }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tixian()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="充值" :visible.sync="chongzhitype" width="30%">
      <div>
        <div class="zhifubox_type">
          <el-input
            v-model="chongzhinum"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 60%"
            placeholder="请输入充值金额"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="chongzhiaxo()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提现提示" :visible.sync="tixiantruetype" width="30%">
      <div>
        <p>提现申请已提交后台审核，将在1-3个工作日完成审核，请耐心等待!</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tixiantrueclick()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="zfbnumzero" width="30%">
      <div>
        <p>请绑定实名认证中您的支付宝信息，再进行提现。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="zfbnumzerobtn()">立即前往</el-button>
      </span>
    </el-dialog>
    <zhifu :all="dataall" />
  </div>
</template>
<script>
import zhifu from "../zhifu";
export default {
  components: { zhifu },
  data() {
    return {
      chongzhitype: false,
      tixiantype: false,
      tixianyuetype: true,
      radioval: "2",
      tixianyuenum: "",
      chongzhinum: "",
      activeName: "first",
      pagesize: 10,
      total: 0,
      tableData: [],
      pay_state: 2,
      timeall: "",
      userinfo: {},
      userdata: {},
      can_moneyll: 0,
      dataall: {
        type: false,
        money: "",
        ddnnum: "",
        class_id: "",
      },
      tixiantruetype: false,
      zfpaypass: "",
      r_zfbnum: "",
      zfbnumzero: false,
    };
  },
  methods: {
    handleClick(index) {},
    currentchange(index) {
      // index=页码
      this.axiosqq(index);
    },
    axiosqq(index) {
      var that = this;
      that.tableData = [];
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/myWallet",
          method: "post",
          data: {
            pay_state: that.pay_state,
            start_time: that.timeall[0],
            end_time: that.timeall[1],
            page: index,
            limit: that.pagesize,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.total = res.data.result.count;
            for (var i = 0; i < res.data.result.list.length; i++) {
              if (res.data.result.list[i].pay_status == 0) {
                res.data.result.list[i].pay_status = "尚未支付";
              } else if (res.data.result.list[i].pay_status == 1) {
                res.data.result.list[i].pay_status = "已支付";
              } else if (res.data.result.list[i].pay_status == 2) {
                res.data.result.list[i].pay_status = "支付失败";
              } else if (res.data.result.list[i].pay_status == 3) {
                res.data.result.list[i].pay_status = "订单失效";
              }
              if (res.data.result.list[i].class_id == 1) {
                res.data.result.list[i].class_id = "项目任务";
              } else if (res.data.result.list[i].class_id == 4) {
                res.data.result.list[i].class_id = "购买作品";
              } else if (res.data.result.list[i].class_id == 6) {
                res.data.result.list[i].class_id = "购买简历";
              } else if (res.data.result.list[i].class_id == 7) {
                res.data.result.list[i].class_id = "购买课程";
              } else if (res.data.result.list[i].class_id == 12) {
                res.data.result.list[i].class_id = "充值金额";
              } else if (res.data.result.list[i].class_id == 10) {
                res.data.result.list[i].class_id = "保证金支付";
              } else if (res.data.result.list[i].class_id == 11) {
                res.data.result.list[i].class_id = "余额提现";
              }
            }
            that.tableData = res.data.result.list;
          } else {
          }
        });
    },
    moneyallaxios() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getUserInfoData",
          method: "post",
          data: {
            userid: that.userinfo.userid,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.userdata = res.data.result;
            that.can_moneyll =
              Number(that.userdata.can_money) +
              Number(that.userdata.freeze_money);
          }
        });
    },
    tixian() {
      var that = this;
      let paypass = this.$md5(that.zfpaypass);
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/withdrawal",
          method: "post",
          data: {
            money: that.tixianyuenum,
            type: that.radioval,
            paypass,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.tixiantype = false;
            that.tixiantruetype = true;
          } else {
            that.$message(res.data.messages);
          }
        });
    },
    tixiantrueclick() {
      this.tixiantruetype = false;
      location.reload();
    },
    chongzhiaxo() {
      var that = this;
      this.chongzhitype = false;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/recharge",
        method: "post",
        data: {
          money: that.chongzhinum,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.dataall = {
            type: true,
            money: that.chongzhinum,
            ddnnum: res.data.result,
            class_id: "",
          };
        } else {
        }
      });
    },
    tixianbtn() {
      var that = this;
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/getIdentity",
          method: "post",
          data: {},
        })
        .then((res) => {
          if (res.data.code == 1) {
            if (
              res.data.result.alipay == "" ||
              res.data.result.alipay == null
            ) {
              that.zfbnumzero = true;
            } else {
              that.r_zfbnum = res.data.result.alipay;
              that.tixiantype = true;
            }
          } else {
            that.zfbnumzero = true;
          }
        });
    },
    zfbnumzerobtn() {
      this.$router.push({
        path: "/Userhome/Userhl_info",
        query: { data: "2" },
      });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.axiosqq(1);
    this.moneyallaxios();
  },
  watch: {
    activeName() {
      if (this.activeName == "first") {
        this.pay_state = 2;
      } else {
        this.pay_state = 1;
      }
      this.axiosqq(1);
    },
    timeall() {
      this.axiosqq(1);
    },
    radioval() {
      if (this.radioval == "2") {
        this.tixianyuetype = true;
      } else {
        this.tixianyuetype = false;
        this.tixianyuenum = this.userdata.bond_money;
      }
    },
  },
};
</script>
